import React, { useEffect, useState, useRef } from "react";
import MetisMenu from "metismenujs";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import dbIcon from "../../../images/svg/Group 1171274941.svg";
import dbBlue from "../../../images/svg/Group 1171274941 (1).svg";
import patientIcon from "../../../images/svg/Crowd Patient.svg";
import patientBlue from "../../../images/svg/Crowd Patient (1).svg";
import docIcon from "../../../images/svg/Vector.svg";
import docBlue from "../../../images/svg/Vector (1).svg";
import pharmacyIcon from "../../../images/svg/drug-1.svg";
import pharmacyBlue from "../../../images/svg/drug-1 - Copy.svg";
import labIcon from "../../../images/svg/management-service-1.svg";
import labBlue from "../../../images/svg/management-service-1 - Copy.svg";
import appoiIcon from "../../../images/svg/Icon.svg";
import appoiBlue from "../../../images/svg/Icon (1).svg";
import paymentSvg from "../../../images/svg/payment.svg";
import paymentSvgWhite from "../../../images/svg/payment(1).svg";
import { dashboard } from "../../../services/AuthService";

const MM = ({ children }) => {
  const menuRef = useRef(null);

  useEffect(() => {
    const mm = new MetisMenu(menuRef.current);
    return () => {
      mm.dispose();
    };
  }, []);

  return (
    <div className="mm-wrapper">
      <ul className="metismenu" ref={menuRef}>
        {children}
      </ul>
    </div>
  );
};

const SideBar = () => {
  const [loveEmoji, setLoveEmoji] = useState(false);
  const [filter, setFilter] = useState("");
  const [type, setType] = useState("");

  function getDashboardData() {
    // setLoader(true);
    dashboard(filter)
      .then((response) => {
        setType(response.data?.data?.type);
      })
      .catch((error) => {
        console.log(error?.response, "heloo");
      });
  }

  useEffect(() => {
    getDashboardData();
  }, [filter]);

  useEffect(() => {
    const btn = document.querySelector(".nav-control");
    const wrapper = document.querySelector("#main-wrapper");

    const toggleFunc = () => {
      wrapper.classList.toggle("menu-toggle");
    };

    btn.addEventListener("click", toggleFunc);
    return () => {
      btn.removeEventListener("click", toggleFunc);
    };
  }, []);

  const path = window.location.pathname.split("/").pop();

  const dashBoard = [
    "",
    "analytics",
    "events",
    "order-list",
    "general-customers",
    "reviews",
    "task",
  ];
  const users = ["patients-management"];
  const lab = ["lab-management"];
  const pharmacy = ["pharmacy-management"];
  const appointment = ["appointment-requests"];
  const doc = ["doctors-management"];
  const admin = ["sub-admin"];
  const payment = ["payment-request"];

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <MM>
          <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/">
              <img
                src={dashBoard.includes(path) ? dbBlue : dbIcon}
                width={20}
                className="mr-3"
                alt="Dashboard"
              />
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li className={`${users.includes(path) ? "mm-active" : ""}`}>
            <Link to="patients-management" className="ai-icon">
              <img
                src={users.includes(path) ? patientBlue : patientIcon}
                width={20}
                className="mr-3"
                alt="Patient Management"
              />
              <span className="nav-text">Patient Management</span>
            </Link>
          </li>
          {type === "Admin" && (
            <li className={`${doc.includes(path) ? "mm-active" : ""}`}>
              <Link to="doctors-management" className="ai-icon">
                <img
                  src={doc.includes(path) ? docBlue : docIcon}
                  width={20}
                  className="mr-3"
                  alt="Doctor Management"
                />
                <span className="nav-text">Doctor Management</span>
              </Link>
            </li>
          )}
          {type === "Admin" && (
            <li className={`${pharmacy.includes(path) ? "mm-active" : ""}`}>
              <Link to="pharmacy-management" className="ai-icon">
                <img
                  src={pharmacy.includes(path) ? pharmacyBlue : pharmacyIcon}
                  width={20}
                  className="mr-3"
                  alt="Pharmacy Management"
                />
                <span className="nav-text">Pharmacy Management</span>
              </Link>
            </li>
          )}
          {type === "Admin" && (
            <li className={`${lab.includes(path) ? "mm-active" : ""}`}>
              <Link to="lab-management" className="ai-icon">
                <img
                  src={lab.includes(path) ? labBlue : labIcon}
                  width={20}
                  className="mr-3"
                  alt="Lab Management"
                />
                <span className="nav-text">Lab Management</span>
              </Link>
            </li>
          )}
          <li className={`${appointment.includes(path) ? "mm-active" : ""}`}>
            <Link to="appointment-requests" className="ai-icon">
              <img
                src={appointment.includes(path) ? appoiBlue : appoiIcon}
                width={20}
                className="mr-3"
                alt="Appointments"
              />
              <span className="nav-text">Appointments</span>
            </Link>
          </li>
          {type === "Admin" && (
            <li className={`${admin.includes(path) ? "mm-active" : ""}`}>
              <Link to="sub-admin" className="ai-icon">
                <img
                  src={admin.includes(path) ? paymentSvg : paymentSvgWhite}
                  width={20}
                  className="mr-3"
                  alt="Sub Admin"
                />
                <span className="nav-text">Sub admin</span>
              </Link>
            </li>
          )}
          <li className={`${payment.includes(path) ? "mm-active" : ""}`}>
            <Link to="payment-request" className="ai-icon">
              <img
                src={payment.includes(path) ? paymentSvg : paymentSvgWhite}
                width={20}
                className="mr-3"
                alt="Payment Request"
              />
              <span className="nav-text">Payment Request</span>
            </Link>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
